import { createStore } from 'vuex'

export default createStore({
  state: {
    hisid: '',
    hisname: '',
    ysinfo: {},
    bcinfo: {},
    userinfo: '',
    ghquery: {},
    userquery: {},
    hisuser: {}
  },
  getters: {
  },
  mutations: {
    sethisuser(state, value) {
      state.hisuser = value
    },
    setuserquery(state, value) {
      state.userquery = value
    },
    sethisid(state, value) {
      state.hisid = value
    },
    sethisname(state, value) {
      state.hisname = value
    },
    setysinfo(state, value) {
      state.ysinfo = value
    },
    setbcinfo(state, value) {
      state.bcinfo = value
    },
    setuserinfo(state, value) {
      state.userinfo = value
    },
    setghquery(state, value) {
      state.ghquery = value
    },
  },
  actions: {
  },
  modules: {
  }
})
