<template>
    <div class="qqpage">
        <div style="height: 100%;">
            <div class="weui-tab">
                <div id="panel1" role="tabpanel" aria-labelledby="tab1" class="weui-tab__panel">
                    <header class='demos-header'>
                        <!-- <img :src="'/api/api/getlogo?hisname=' + hisname + '&hisid=' + hisid"
                            style="height: 100%;width: 98%;" /> -->
                        <div class="demos-title">{{ hisid.indexOf('(yd)') > 0 ? "移动互联网医疗云":"联创互联网医疗云" }}</div>
                        <div class="demos-title" style="font-size: 26px;">{{ hisname }}</div>
                    </header>
                    <router-view :key="$route.fullPath"></router-view>

                </div>
                <div role="tablist" aria-label="选项卡标题" class="weui-tabbar">
                    <a v-on:click="setapp('create')" id="tab1" role="tab" class="weui-tabbar__item setmouse"
                        :class="{ 'weui-bar__item_on inverted': isname.indexOf('create') >= 0 }">
                        <img src="./images/icon_tabbar.png" alt="" class="weui-tabbar__icon">
                        <p id="t1_title" aria-hidden="true" class="weui-tabbar__label" style="font-size: 14px;">预约挂号</p>
                    </a>
                    <a v-on:click="setapp('query')" id="tab2" role="tab" class="weui-tabbar__item setmouse"
                        :class="{ 'weui-bar__item_on inverted': isname.indexOf('query') >= 0 }">
                        <img src="./images/icon_tabbar.png" alt="" class="weui-tabbar__icon">
                        <p aria-hidden="true" id="t2_title" class="weui-tabbar__label" style="font-size: 14px;">挂号查询</p>
                    </a>
                    <a v-on:click="setapp('info')" id="tab3" role="tab" class="weui-tabbar__item setmouse"
                        :class="{ 'weui-bar__item_on inverted': isname.indexOf('info') >= 0 }">
                        <img src="./images/icon_tabbar.png" alt="" class="weui-tabbar__icon">
                        <p id="t3_title" aria-hidden="true" class="weui-tabbar__label" style="font-size: 14px;">关于我的</p>
                    </a>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { db } from '@/js/common.js'
export default {
    name: 'app',
    data() {
        return {
            appid: 'wx6310e0ba3ce2f0f6',
            appsecret: 'aa4d53f1257dee24586dd37f4cf6db0a',
            isname: '',
            hisid: '',
            hisname: '森林木医疗门诊部',
            mainname: '联创互联网医疗云',
            countid: 1
        }
    },
    watch: {
        $route(to, from) {
            console.log('main', to.name, from.name, to.path)
            this.isname = to.path;
        }
    },
    // beforeUpdate() {
    //     console.log('this.isupdate',this.isupdate) 
    //     console.log('beforeUpdate', this.countid++)
    // },
    async mounted() {
        let code = this.geturlname("code")
        let openid = localStorage.getItem('openid')

        if (process.env.NODE_ENV === 'development') {
            console.log(process.env.NODE_ENV)
            openid = 'oTBjP6gLqGO23R1mMpg59Vm5AXZ4'
            localStorage.setItem('openid', openid)
        }
        else {
            console.log(process.env.NODE_ENV)
        }


        if (openid) {
            await this.gethisuser(openid)
            this.isname = 'create'
            this.$router.replace({
                path: "/create"
            });
        } else if (code) {
            let lg;
            const dataobj = await axios({
                method: 'post',
                url: '/api/api/openid',
                data: { code: code },
                before: lg = this.$weui.loading('loading...'),
            }).then(function (res) {
                lg.hide();
                return res.data;
            }).catch(function (error) {
                lg.hide();
                return { message: error.message }
            });
            console.log(dataobj)
            if (dataobj.openid) {
                this.hisid = dataobj.hisid;
                this.hisname = dataobj.hisname;
                localStorage.setItem('openid', dataobj.openid)
                this.$store.commit('sethisuser', dataobj);
                this.isname = 'create'
                this.$router.replace({
                    path: "/create"
                });
            }
        } else {
            let allurl = window.location.href
            var arrUrl = allurl.split("?");
            var url = arrUrl[0];
            let sendurl = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + this.appid + "&redirect_uri=" + url + "&response_type=code&scope=snsapi_base&state=kcrjwx#wechat_redirect";
            window.location.href = sendurl;
        }
    },
    methods: {
        setapp(name) {
            //console.log(name)
            this.isname = name
            this.$router.replace({
                path: "/" + name
            });
        },
        geturlname(name) {
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
            var r = window.location.search.substr(1).match(reg); //search,查询？后面的参数，并匹配正则
            if (r != null) return decodeURI(r[2]);
            return null;
        },
        async gethisuser(openid) {
            let outdata = await db('querydb', { procname: "gethisuser", paras: { openid: openid } })
            console.log('hisuser', outdata);
            if (outdata.openid) {
                this.hisid = outdata.hisid;
                this.hisname = outdata.hisname;
                this.$store.commit('sethisuser', outdata);
            }
        },
    }
}
</script>

<style>
html,
body {
    height: 100%;
    -webkit-tap-highlight-color: transparent;
    background-color: #fff;
}

#app {
    height: 100%;
}

.qqpage {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    opacity: 1;
    z-index: 1
}

.pagehd {
    padding-left: 20px;
    padding-right: 20px;
    padding: 40px
}

.setmouse {
    cursor: pointer;
}

.inverted {
    filter: invert(0%);
}
</style>
