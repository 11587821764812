// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'

// createApp(App).use(store).use(router).mount('#app')


import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'weui'
import './css/demos.css'
//import './css/example.css'
import store from './store' 

import weui from 'weui.js'

const app = createApp(App)
app.use(router)
app.config.globalProperties.$store = store
app.config.globalProperties.$weui = weui
app.mount('#app')
