import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  // {
  //   path: '/',
  //   redirect: '/create'
  // },
  {
    path: '/mmain',
    name: 'mmain',
    component: () => import('../views/mmain.vue'),
    meta:
    {
      title: '主页面',
      requireAuth: true
    },
  }, 
  {
    path: '/create',
    name: 'create',
    component: () => import('../views/create.vue'),
    meta:
    {
      title: '预约挂号',
      requireAuth: true
    },
  },
  {
    path: '/query',
    name: 'query',
    component: () => import('../views/query.vue'),
    meta:
    {
      title: '挂号查询',
      requireAuth: true
    },
  },
  {
    path: '/query/querylist',
    name: 'querylist',
    component: () => import('../views/querylist.vue'),
    meta:
    {
      title: '挂号明细',
      requireAuth: true
    },
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('../views/info.vue'),
    meta:
    {
      title: '关于我的',
      requireAuth: true
    },
  },
  // {
  //   path: '/create/qr2',
  //   name: 'qr2',
  //   component: () => import('../views/qr2.vue'),
  //   meta:
  //   {
  //     title: '扫码医院',
  //     requireAuth: true
  //   },
  // },
  // {
  //   path: '/create/mzmsg',
  //   name: 'mzmsg',
  //   component: () => import('../views/mzmsg.vue'),
  //   meta:
  //   {
  //     title: '医院提示信息',
  //     requireAuth: true
  //   },
  // },
  {
    path: '/create/yslist',
    name: 'yslist',
    component: () => import('../views/yslist.vue'),
    meta:
    {
      title: '医生列表',
      requireAuth: true
    },
  },
  {
    path: '/info/newuser',
    name: 'newuser',
    component: () => import('../views/newuser.vue'),
    meta:
    {
      title: '在线建档',
      requireAuth: true
    },
  },
  {
    path: '/info/adduser',
    name: 'adduser',
    component: () => import('../views/adduser.vue'),
    meta:
    {
      title: '添加用户',
      requireAuth: true
    },
  },
  {
    path: '/create/newgh',
    name: 'newgh',
    component: () => import('../views/newgh.vue'),
    meta:
    {
      title: '挂号信息',
      requireAuth: true
    },
  },
  {
    path: '/info/casunuser',
    name: 'casunuser',
    component: () => import('../views/casunuser.vue'),
    meta:
    {
      title: '添加用户',
      requireAuth: true
    },
  },
  {
    path: '/info/mingxiuser',
    name: 'mingxiuser',
    component: () => import('../views/mingxiuser.vue'),
    meta:
    {
      title: '用户信息',
      requireAuth: true
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to) => {
  
  console.log(to.meta.requireAuth)
  if (to.meta.title) {
    document.title = to.meta.title;
  }
});

export default router
